$(window).scroll(function() {
  var scroll = $(window).scrollTop();
  if (scroll >= 10) {
      $(".headerTopCol").addClass("stuck");
  } else {
      $(".headerTopCol").removeClass("stuck");
  }
})

$('.userCol a').click(function(){
  $(this).parents('.userColMain').toggleClass('actDD');
});
$('.toggle').click(function(){
  $('body').toggleClass('actNav');
});
$('.searchTrigger').click(function(){
  $('body').toggleClass('actSearch');
});
$('.menuBackDrop').click(function(){
  $('body').removeClass('actNav actSearch actfilter');
});
$('.filterTrigger').click(function(){
  $('body').addClass('actfilter');
});
$(document).on("click", function(event){
  var $trigger = $(".userColMain");
  if($trigger !== event.target && !$trigger.has(event.target).length){
    $trigger.removeClass("actDD");
  }
});

var swiper = new Swiper('.similarProSlider, .topProSlider', {
  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 10,
    }
  }
});


$('.likeIcon').click(function(){
  $(this).toggleClass('checkedLike')
});
// -----------------------------------------------------------------
var swiper = new Swiper('.testimonailSliderCol', {
  loop: true,
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
  },
  effect: 'fade',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + '<svg class="circ" width="40" height="40" class="circ"><circle class="circ1" cx="20" cy="20" r="15" stroke="#FF0000" stroke-width="2" fill="none"/><circle class="circ2" cx="20" cy="20" r="15" stroke="#FF0000" stroke-width="1" fill="none"/></svg>' + '</span>';
    },
  },
});

// --------------------------------------------



// product detail slider
var galleryThumbs = new Swiper('.proThumbs', {
 spaceBetween: 10,
 slidesPerView: 3,
});
var galleryTop = new Swiper('.proDetailImgSlide', {
 spaceBetween: 10,
 navigation: {
   nextEl: '.swiper-button-next',
   prevEl: '.swiper-button-prev',
 },
 thumbs: {
   swiper: galleryThumbs
 }
});

// increase Decrease product value
var minVal = 1, maxVal = 2000; // Set Max and Min values
$(".increaseQty").on('click', function(){
		var $parentElm = $(this).parents(".qtySelector");
		$(this).addClass("clicked");
		setTimeout(function(){
			$(".clicked").removeClass("clicked");
		},100);
		var value = $parentElm.find(".qtyValue").val();
		if (value < maxVal) {
			value++;
		}
		$parentElm.find(".qtyValue").val(value);
});
$(".decreaseQty").on('click', function(){
		var $parentElm = $(this).parents(".qtySelector");
		$(this).addClass("clicked");
		setTimeout(function(){
			$(".clicked").removeClass("clicked");
		},100);
		var value = $parentElm.find(".qtyValue").val();
		if (value > 1) {
			value--;
		}
		$parentElm.find(".qtyValue").val(value);
	});





var $range = $(".js-range-slider"),
    $inputFrom = $(".js-input-from"),
    $inputTo = $(".js-input-to"),
    instance,
    min = 0,
    max = 1000,
    from = 0,
    to = 0;

$range.ionRangeSlider({
	skin: "round",
    type: "double",
    min: min,
    max: max,
    from: 200,
    to: 800,
    onStart: updateInputs,
    onChange: updateInputs
});
instance = $range.data("ionRangeSlider");

function updateInputs (data) {
	from = data.from;
    to = data.to;

    $inputFrom.prop("value", from);
    $inputTo.prop("value", to);
}

$inputFrom.on("input", function () {
    var val = $(this).prop("value");

    // validate
    if (val < min) {
        val = min;
    } else if (val > to) {
        val = to;
    }

    instance.update({
        from: val
    });
});

$inputTo.on("input", function () {
    var val = $(this).prop("value");

    // validate
    if (val < from) {
        val = from;
    } else if (val > max) {
        val = max;
    }

    instance.update({
        to: val
    });
});
